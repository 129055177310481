/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss?v=1.2.0";
import "assets/demo/demo.css?v=1.2.0";
// pages
import Index from "views/Index.js";
// others
import MobileApp from "views/screen/mobileApp/MobileApp";

import firebase from "firebase/app";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDsYWBlNTrCIJK9PrURd34tdOTkmPcRy8w",
  authDomain: "cv-max-40d25.firebaseapp.com",
  projectId: "cv-max-40d25",
  storageBucket: "cv-max-40d25.appspot.com",
  messagingSenderId: "258731262749",
  appId: "1:258731262749:web:1123655c99af7a04a2d7f4",
  measurementId: "G-3VX9K2ZX61",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/index" render={(props) => <Index {...props} />} />
      <Route path="/mobile-app" render={(props) => <MobileApp {...props} />} />
      <Redirect to="/index" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
