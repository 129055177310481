import React from "react";

import { Col } from "reactstrap";

export default ({ name, competence }) => (
  <Col lg="3">
    <u><h6 style={{fontSize:15, fontWeight:"500"}}>{name}</h6></u>
    {competence.map((item) => (
      <p style={{fontSize:15, fontWeight:"normal"}}> •	{item} </p>
    ))}
  </Col>
);
