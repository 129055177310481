import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import OtherNavBar from "../../../components/Navbars/OtherNavBar";

export default function MobileApp() {
  const [isMobile, setIsMobile] = React.useState(
    !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false
  );

  return (
    <>
      <OtherNavBar />
      <div className="section profile-content">
        <div className="position-relative">
          <section className="section-app section-lg section-shaped ">
            <div className="shape shape-style-1 shape-default mt-5 ">
              <Container>
                <div className="title mt-5">
                  <h2>Preview de notre application mobile</h2>
                </div>
                <div>
                  <h5 style={{ color: "#fff" }}>
                    Les screenshots suivants sont tirés directement de notre application mobile,
                    designé et programmé par moi-même.
                  </h5>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <div className="section section-login">
          <Container style={{ marginTop: isMobile ? -80 : 0 }}>
            <img
              alt="..."
              style={{ width: "100%" }}
              className="img-no-padding img-responsive"
              src={require("assets/img/scover-home.png")}
            />
            <img
              alt="..."
              style={{ width: "100%" }}
              className="img-no-padding img-responsive"
              src={require("assets/img/explore-scover.png")}
            />
            <img
              alt="..."
              style={{ width: "100%" }}
              className="img-no-padding img-responsive"
              src={require("assets/img/profile-scover.png")}
            />

            <img
              alt="..."
              style={{ width: "100%" }}
              className="img-no-padding img-responsive"
              src={require("assets/img/profile2-scover.png")}
            />

            <img
              alt="..."
              style={{ width: "100%" }}
              className="img-no-padding img-responsive"
              src={require("assets/img/profile-collection-scover.png")}
            />

            <img
              alt="..."
              style={{ width: "100%" }}
              className="img-no-padding img-responsive"
              src={require("assets/img/profile-plans-scover.png")}
            />

            <img
              alt="..."
              style={{ width: "100%" }}
              className="img-no-padding img-responsive"
              src={require("assets/img/create-activity.png")}
            />
          </Container>
          <Container style={{ marginTop: isMobile ? -80 : 0 }}></Container>
        </div>
      </div>
    </>
  );
}
